import React from "react";
import { navigate } from "gatsby";
import { Form, Button, Alert } from "react-bootstrap";
import { Trans } from "gatsby-plugin-react-i18next";

import { accountRegisterRequest } from "../actions";

const AccountRegister = (props) => {
  const { dispatch } = props;

  let handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    [
      "email",
      "password",
      "password_confirm",
      "first_name",
      "last_name",
    ].forEach((name) => (data[name] = event.target[name].value));

    dispatch(accountRegisterRequest(data));
  };

  if (props.account_is_logged_in) {
    navigate("/account/");
    return (
      <p>
        <Trans>Redirecting...</Trans>
      </p>
    );
  }

  const { success, data } = props.account_register_response || {};

  // if (success) {
  //   return (
  //     <div className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
  //       <Row>
  //         <Col sm={{ span: 8, offset: 2 }}>
  //           <h2>Register</h2>
  //           <p>
  //             Your registration has been submitted. Please check your email to
  //             verify the account.
  //           </p>
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // }

  function renderSuccessAlert() {
    return (
      <p>
        <Trans>
          Your registration has been submitted. Please check your email to
          verify the account.
        </Trans>
      </p>
    );
  }
  function renderRegistrationForm() {
    if (success === true) {
      return renderSuccessAlert();
    }
    return (
      <>
        {success === false && data && data.detail && (
          <Alert variant="danger">{data.detail}</Alert>
        )}
        <Form.Group controlId="formFirstName">
          <Form.Label>
            <Trans>First name</Trans>
          </Form.Label>
          <Form.Control
            type="text"
            name="first_name"
            autoComplete="first_name"
            isInvalid={success === false && data && data.first_name}
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.first_name &&
              data.first_name.length &&
              data.first_name.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formLastName">
          <Form.Label>
            <Trans>Last name</Trans>
          </Form.Label>
          <Form.Control
            type="text"
            name="last_name"
            autoComplete="last_name"
            isInvalid={success === false && data && data.last_name}
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.last_name &&
              data.last_name.length &&
              data.last_name.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>
            <Trans>Email address</Trans>
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            autoComplete="email"
            isInvalid={success === false && data && data.email}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data && data.email && data.email.length && data.email.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formBasicPassword1">
          <Form.Label>
            <Trans>Password</Trans>
          </Form.Label>
          <Form.Control
            type="password"
            name="password"
            autoComplete="new-password"
            isInvalid={success === false && data && data.password}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.password &&
              data.password.length &&
              data.password.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicPassword2">
          <Form.Label>
            <Trans>Password (again)</Trans>
          </Form.Label>
          <Form.Control
            type="password"
            name="password_confirm"
            autoComplete="new-password"
            isInvalid={success === false && data && data.password_confirm}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.password_confirm &&
              data.password_confirm.length &&
              data.password_confirm.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button variant="primary" type="submit" block>
            <Trans>Submit</Trans>
          </Button>
        </Form.Group>
        <div className="text-center account-link">
          <span className="btn cursor-default px-0">
            <Trans>Have an account?</Trans>
          </span>
          <Button variant="link" href="/account/login">
            <Trans>Log In</Trans>
          </Button>
        </div>
        <div className="text-center">
          <Button variant="link" href="mailto:geoguard-help@element84.com">
            <Trans>Help Desk</Trans>
          </Button>
        </div>
      </>
    );
  }

  return (
    <Form onSubmit={handleSubmit} noValidate className="form-signin">
      <div className="form-signin-groups">{renderRegistrationForm()}</div>
    </Form>
  );
};

export default AccountRegister;
