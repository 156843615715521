import { graphql } from "gatsby";
import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/Layout";
import AccountRegister from "../../components/AccountRegister";
import LayoutFloating from "../../components/LayoutFloating";
import { SITE_TITLE } from "../../lib/constants";

const Register = (props) => {
  return (
    <Layout pageName="account-register" showHeader={false}>
      <LayoutFloating title={SITE_TITLE}>
        <AccountRegister {...props} />
      </LayoutFloating>
    </Layout>
  );
};

export default connect((state) => state)(Register);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
